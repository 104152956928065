@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"

































.performance-subcategory
  display: flex
  align-items: center
  margin-top: 16px

  &__percentage
    font-size: 13px
    font-weight: 600
    color: $color-ink
    background: $color-ink-lightest
    padding: 4px 8px
    box-sizing: border-box
    border-radius: 8px
    width: 56px
    text-align: center

  &__label
    font-size: 13px
    margin-left: 16px
    color: $color-ink-light

    +mq-m
      margin-left: 8px

